<template>
    <div>
        <div class="l-container">
            <el-backtop target=""><i class="el-icon-download"></i></el-backtop>
            <div class="theme-img__title"></div>
            <div class="user-info">
                <div class="user-info__content">
                    <img src="~@/assets/images/study/tou.jpg" alt="" class="user-info__avatar">
                    <div class="user-info__name">
                        <i class="fa fa-venus"></i>
                        <span class="user-info__name--text">小黄帽</span>
                    </div>
                    <div class="user-info__level">
                        <img src="@/assets/images/study/gem.png" alt="" class="user-info__gem">
                        <span class="user-info__level--text">荣耀黄金II</span>
                    </div>
                    <div class="user-info__progress">
                        <el-progress :percentage="percentage" :stroke-width="12" :show-text="textShow"></el-progress>
                        <div class="user-info__detail">
                            <span class="content-num">51</span>/<span class="content-total">100</span>
                        </div>
                    </div>
                    <div class="user-info__desc">
                        <i class="fa fa-tags"></i>
                        <span class="user-info__desc--text">怕，你就会输一辈子</span>
                    </div>
                </div>
            </div>
            <div class="user-form">
                <div class="user-form__title">
                    <div class="title-list">
                        <span class="title-list__item list__item--hover">个人信息</span>
                        <span class="title-list__item">修改密码</span>
                    </div>
                </div>
                <el-form class="user-form__content" ref="form" :model="form" label-width="100px">
                    <el-form-item class="content-name">
                        <el-col :span="11">
                            <el-form-item label="姓名：">
                                <el-input v-model="form.name" class="same-name-width"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="昵称：">
                                <el-input v-model="form.nickname" class="same-name__width"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item class="content-school">
                        <el-col :span="11">
                            <el-form-item label="学校：">
                                <el-input v-model="form.school" class="same-name__width"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="年级：">
                                <el-input v-model="form.grade" class="content-grade same-name__width"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="性别：">
                        <el-radio-group v-model="form.resource" size="medium">
                        <el-radio label="男">男<i class="fa fa-mars"></i></el-radio>
                        <el-radio label="女">女<i class="fa fa-venus"></i></el-radio>
                        <el-radio label="保密"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="个人标签：" class="content-course">
                        <el-col :span="7">
                            <el-form-item label="语文">
                                <el-input v-model="form.chinese" class="same-course__width"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="数学">
                                <el-input v-model="form.math" class="same-course__width"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="7">
                            <el-form-item label="英语">
                                <el-input v-model="form.english" class="same-course__width"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="标签：">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item class="content-more" style="display: none">
                        <el-form-item>
                            <el-col :span="11">
                                <el-form-item label="班级：">
                                    <el-input v-model="form.class" class="same-name-width"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="年龄：">
                                    <el-input v-model="form.age" class="same-name__width"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="11">
                                <el-form-item label="电话号码：">
                                    <el-input v-model="form.phone" class="same-name-width"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="微信号：">
                                    <el-input v-model="form.wechat" class="same-name__width"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="11">
                                <el-form-item label="QQ号：">
                                    <el-input v-model="form.QQ" class="same-name-width"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="邮箱地址：">
                                    <el-input v-model="form.email" class="same-name__width"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item>
                        <el-button>修改更多信息 <i class="el-icon-caret-bottom"></i> </el-button>
                        <el-button style="display: none">隐藏信息 <i class="el-icon-caret-top"></i> </el-button>
                    </el-form-item>
                </el-form>
                <el-form  class="user-form__content" ref="form" :model="form" label-width="100px">
                    <el-form-item label="原密码：">
                        <el-input type="password" v-model="form.oldPass" autocomplete="off" class="pass-same__width"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码：">
                        <el-input type="password" v-model="form.NewPass" autocomplete="off" class="pass-same__width"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码：">
                        <el-input type="password" v-model="form.checkPass" autocomplete="off" class="pass-same__width"></el-input>
                    </el-form-item>
                </el-form>
                <div class="content-btn">
                    <el-button class="content-btn__exit">取消</el-button>
                    <el-button class="content-btn__confirm">确定</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textShow: false,
            percentage: 10,
            form: {
                name: '',
                nickname: '',
                school: '',
                grade: '',
                resource: '',
                chinese: '',
                math: '',
                english: '',
                desc: '',
                class: '',
                age: '',
                phone: '',
                wechat: '',
                QQ: '',
                email: '',
                oldPass: '',
                newPass: '',
                checkPass: ''
            }   
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/css/user_center.scss';
</style>